import React, { useState } from 'react'
import doc from "../../../../images/doc_1.svg"
import { CloseOutlined, FilePdfOutlined } from "@ant-design/icons";

const EquipmentDoc = ({ data,type }) => {
    const [path, setPath] = useState(null);
    const docPathBigHandler = (index, val) => {
        setPath(val.doc_path)
        document.querySelector(".equ_doc_img_big").style.display = "block"
    }
    const docPatCloseHandler = (index) => {
        document.querySelector(".equ_doc_img_big").style.display = "none"
    }
    return (
        <>
            <div className='equipment_oil_second'>
               <h3>attached documents</h3>
                {data && data.length > 0 && 
                <div className="equ_doc_img">
                    {data && data.length > 0 && data.map((val, index) => {
                            let picture = val.doc_path;
                            let ext = picture && picture.split(".").pop();
                            if (val.doc_name === 'Safety Sheets (SDS)') {
                                return <>
                                    {
                                        (() => {
                                            if (ext === "png") {
                                                return <>
                                                    <figure  onClick={() => { docPathBigHandler(index, val) }}><img src={val.doc_path} alt="documents" /><figcaption>{index + 1}.{val.doc_path.split(".").pop()}</figcaption></figure>


                                                </>
                                            }
                                            else if (ext === "jpg"||ext==='jpeg') {
                                                return <>
                                                    <figure onClick={() => docPathBigHandler(index, val)}><img src={val.doc_path} alt="documents" /><figcaption>{index + 1}.{val.doc_path.split(".").pop()}</figcaption></figure>

                                                </>
                                            }
                                            else if (ext === "gif") {
                                                return <>
                                                    <figure onClick={() => docPathBigHandler(index)}><img src={val.doc_path} alt="documents" /><figcaption>{index + 1}.{val.doc_path.split(".").pop()}</figcaption></figure>

                                                </>
                                            }
                                            else {
                                                return <>
                                                    <a href={val.doc_path} >
                                                        <figure> <FilePdfOutlined className="equ_doc_icons_download" /><figcaption>{index + 1}.{val.doc_path.split(".").pop()}</figcaption></figure>
                                                    </a>
                                                </>
                                            }
                                        })()
                                    }
                                    <div className="equ_doc_img_big">
                                        <div className="equ_doc_img_big_content">
                                            <CloseOutlined onClick={() => docPatCloseHandler(index)} className="docPat_close" />
                                            <img src={path} alt="documents" />
                                        </div>
                                    </div>

                                </>
                            }
                        })
                    }


                </div>
                }
                {data && data.length === 0 && <div className='equ_doc_no_data'>no documents attached</div>}

            </div>
        </>
    )
}
export default EquipmentDoc;